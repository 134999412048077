import React from 'react'

import { ProductHero } from 'src/components/product/hero'

export interface NestedProductHeroProps {
  data: {
    product: {
      content: {}
      linkedProducts?: any[]
      variants: any[]
      heroImages: any[]
      chef?: {}
    }
    alignment?: string
  }
}

export const NestedProductHero = ({ data }: NestedProductHeroProps) => {
  const {
    product: {
      content,
      linkedProducts,
      variants,
      heroImages,
      chef
    }
  } = data
  return (
    <div className='x'>
      <ProductHero
        main={content.main}
        linkedProducts={linkedProducts}
        variants={variants}
        images={heroImages}
        quick={false}
        chef={chef}
        product={content.shopify} />
    </div>
  )
}

export default NestedProductHero