import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import BlockContent from "@sanity/block-content-to-react"
import { useEmblaCarousel } from 'embla-carousel/react'

import { Serializer } from "src/utils/serializer"
import { Image } from 'src/components/image'
import { ProductForm } from './form'

import {
  LargeArrow
} from 'src/components/svgs'


export const ProductHero = ({ product, chef, quick = true, images, linkedProducts, variants, main: { title, badgeText, externalProductLink, customTitle, productType, productDescription, backgroundColor = 'bg--green', mainImage }}: {
  main: {
    title?: string
    subTitle?: string
    slug: {}
    customTitle?: string
    badgeText?: string
    productType?: string
    productDescription?: []
    mainImage: {
      asset: {
        _id: string
      }
    }

    externalProductLink?: string
    backgroundColor?: string
  }
  images?: Array<{
    image: {
      imageId: string
      altText?: string
    }
  }>
  linkedProducts?: any[]
  variants: any[]
  chef: {}
  quick?: boolean
  product: {
    defaultPrice: string
    productId: number
  }
}) => {

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, center: true })
  const [active, setActive] = useState(0)

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.on('select', () => {
        setActive(emblaApi.selectedScrollSnap())
      })
    }
  }, [emblaApi])


  return (
    <div className={cx('product__hero   bc')}>
      <div className=''>
        <div className='df fw row gutter--none'>
          <div className='col c14 pr c7--800 x'>
            <div className='df y jce aie product__hero-height pr'>
              {images ? (
                <>
                  <div className='of--hidden y x product__hero-carousel' ref={emblaRef}>
                    <div className='x y df product__hero-slideshow'>
                      {images.map(({ image }, i) => (
                        <div key={i} className='x  y pr product__hero-slide'>
                          <Image className='x pa y' imageId={image.imageId}   alt={image.altText} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='psy product__hero-thumb-row x pl3 pr3 show--800'>
                    <div className='df jcb x aie'>
                      <div>
                        {images.map(({ image }, i) => (
                          <button key={i} onClick={() => emblaApi?.scrollTo(i)} className={cx('product__hero-slideshow-thumb pr', {
                            'active': i === active
                          })}>
                            <Image className='x top left pa y' width={70}   imageId={image.imageId} alt={image.altText} />
                          </button>
                        ))}
                      </div>
                      <div className='product__hero-slideshow-controls'>
                        <div>
                          <button className='product__hero-slideshow-arrow pr push-down' onClick={() => emblaApi?.scrollNext()}>
                            <LargeArrow  className='' />
                          </button>
                        </div>
                        <button className='product__hero-slideshow-arrow' onClick={() => emblaApi?.scrollPrev()}>
                          <LargeArrow className='rotate' />
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {mainImage && (
                    <Image className='y x' imageId={mainImage.asset._id} src={mainImage.url} alt={title} />
                  )}
                </>
              )}
            </div>
          </div>
          <div className='col c14 c7--800 p4--800'>
            <div className='df fw jcc aic y pt6--800 pb2--800'>
              <div className='container--500 p2 mxa'>
                <div className='tc--800 mb2 mb2--800'>
                  {badgeText && (
                    <div className='tc df jcc aic'>
                      <div className='product__card-avatar ba bc s12 medium  mr1 mb2 caps dib mb df'><span className='medium'>{linkedProducts && productType === 'deck' && linkedProducts.length} {badgeText}</span></div>
                    </div>
                  )}
                  <h1 className='h2 tc mb2' dangerouslySetInnerHTML={{__html: customTitle ? customTitle: title }} />
                  {chef && (
                    <div className='product__hero-chef mxa'>
                      <div className='df x row aic ba bc gutter--none'>
                        {chef.image && (
                          <div className='col c3 br bc pr y deck__chef-image'>
                            <Image className='pa x y object--fit' imageId={chef.image.imageId} alt={chef.altText} />
                          </div>
                        )}
                        <div className='col tc c11'>
                          <div className='p1'>
                            <span className='s12 courier'>By Chef {chef.name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className='mb2 show--800 s20--800'>
                  {productDescription && (<BlockContent blocks={productDescription} serializers={Serializer} />)}
                </div>
                <div>
                  <div>
                    {!externalProductLink ? (
                      <ProductForm
                        {...product}
                        variants={variants}
                        customTitle={customTitle || title}
                        productType={productType}
                        linked={linkedProducts}
                        quick={quick}
                        colorSetting={backgroundColor}
                        showQuantity={true} />
                    ) : (
                      <div className=''>
                        <a className='button dib bn x button founders' href={externalProductLink}>
                          <span>Shop Product</span>
                        </a>
                      </div>
                    )}
                  </div>
                  <div className='mb2 s22 hide--800'>
                    {productDescription && (<BlockContent blocks={productDescription} serializers={Serializer} />)}
                  </div>
                  {/* <div className='tc mt1 mt2--800 show--800 x'>
                    <span className='founders ss18 medium'>Free shipping with every order</span>
                  </div> */}
                </div>
              </div>

              {/* <div className='tc mt1 mt2--800 bt p1 bb hide--800 x'>
                <span className='founders ss18 medium'>Free shipping with every order</span>
              </div> */}
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}